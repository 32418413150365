import React from "react"
import Instagram from "../shared/top/Instagram"
import HeadingM from "../shared/HeadingM"

const TopInstagram = () => {
  return (
    <div className="mt-24">
      <HeadingM title="インスタグラム" target="do" />
      <Instagram accountName="do20220401" />
    </div>
  )
}

export default TopInstagram
