import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import React from "react"
import HeadingM from "../shared/HeadingM"
import Partners from "../shared/top/Partners"

const query = graphql`
  query {
    hukujusou: file(relativePath: { eq: "shared/partners/hukujusou.png" }) {
      childImageSharp {
        gatsbyImageData(width: 200)
      }
    }
    dayhome291: file(relativePath: { eq: "shared/partners/dayhome291.png" }) {
      childImageSharp {
        gatsbyImageData(width: 200)
      }
    }
    minakamiExe: file(
      relativePath: { eq: "shared/partners/minakami-exe.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 200)
      }
    }
  }
`

const TopPartners = () => {
  const data = useStaticQuery(query)
  const items = [
    {
      logo: getImage(data.hukujusou),
      link: "http://akanekai-f.com/",
    },
    {
      logo: getImage(data.dayhome291),
      link: "http://dayhome2910.jp/",
    },
    {
      logo: getImage(data.minakamiExe),
      link: "https://www.minakami3x3.com/",
    },
  ]
  return (
    <div className="mt-24">
      <HeadingM title="提携事業所" target="do" />
      <Partners items={items} />
    </div>
  )
}

export default TopPartners
