import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../../components/do/Layout"
import TopConcept from "../../components/do/TopConcept"
import TopContact from "../../components/do/TopContact"
import TopFeature from "../../components/do/TopFeature"
import TopInstagram from "../../components/do/TopInstagram"
import TopPartners from "../../components/do/TopPartners"
import TopSlider from "../../components/do/TopSlider"
import SEO from "../../components/Seo"
import About from "../../components/shared/top/About"
import OpeningShutter from "../../components/shared/top/OpeningShutter"

const query = graphql`
  query {
    logo: file(relativePath: { eq: "do/logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 100)
      }
    }
  }
`

const index = () => {
  const data = useStaticQuery(query)
  return (
    <Layout>
      <SEO siteTitle="Do" />
      <OpeningShutter logo={getImage(data.logo)} target="do" />
      {/* <div className="text-3xl font-kiwiMaru">準備中</div> */}
      <TopSlider />
      <TopConcept bgDotes="md:bg-doFourDots" />
      <About target="do" isDo />
      <TopFeature />
      <TopInstagram />
      <TopPartners />
      <TopContact />
    </Layout>
  )
}

export default index
