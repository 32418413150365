import React from "react"
import { VFC } from "react"

type Props = {
  bgDotes: string
}

const DoConcept: VFC<Props> = ({ bgDotes }) => {
  return (
    <div className="relative font-kiwiMaru">
      <div
        className={`p-8 my-12 md:my-20 text-xl leading-relaxed text-left bg-repeat-round md:text-2xl ${bgDotes} md:leading-relaxed`}
      >
        Doでは <br className="hidden md:block" />
        「できる・できた」喜びを実感でき、その経験を社会生活に生かせるよう積み上げていきます。
        <br className="hidden md:block" />
        ３つの【Do】「挑戦・やる・できる」を大切に、一人ひとりの【Do】を応援します。
        <br className="hidden md:block" />
        ・Do the challenge
        <br className="hidden md:block" />
        ・Do the right thing
        <br className="hidden md:block" />
        ・Do the best
      </div>
    </div>
  )
}

export default DoConcept
