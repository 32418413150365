import React from "react"
import Feature from "../shared/top/Feature"
import HeadingM from "../shared/HeadingM"

const TopFeature = () => {
  const items = [
    {
      title: "cafeでの接客、商品の準備、販売",
      descriptions: [
        "Do CAFE併設のため cafeでの接客、商品の準備、販売を行います",
      ],
    },
    {
      title: "内部作業",
      descriptions: [
        "受注による内部作業（ラベル剥がし・組み立て作業etc）",
      ],
    },
    {
      title: "外部作業",
      descriptions: [
        "受注による外部作業（草むしり・掃除etc）",
      ],
    },
    {
      title: "余暇活動",
      descriptions: [
        "月に1日は必ず利用者の皆様と相談し余暇活動を取り入れています",
      ],
    },
    {
      title: "季節毎の催し活動",
      descriptions: [
        "季節感を感じるために季節毎の催し活動を大切にしています",
      ],
    },
    {
      title: "送迎サービスも実施しています",
      descriptions: [
        "※沼田駅から徒歩３分、バス停より１０秒のため自立通所のお手伝いをさせていただきます",
      ],
    },
  ]

  return (
    <div className="mt-24">
      <HeadingM title="Doの特徴" target="do" />
      <Feature items={items} target="do" />
    </div>
  )
}

export default TopFeature
